<template>
  <div class="teacher-guide pb-10">

    <h1 class="text-drawdown font-display md:text-mdlg text-mdbs tracking-wide leading-tight">
        Teacher Guide
    </h1>
    <h2 class="pb-2 font-light text-drawdown text-smd tracking-wide">
      Key word color coding system
    </h2>
    <p class="pb-2">
      <span class="font-bold text-forest">Climate Science Words:</span> Terms relevant to many solutions that may be new to students when learning about climate science. These are all defined in the <router-link to="/glossary" class="underline hover:text-drawdown">glossary</router-link>.
    </p>
    <p class="pb-2">
      <span class="bg-sun25">Key Solution Words:</span> Terms relevant to the current solution that may be new to students reading the solution. These are either defined in the text or with an accompanying pop-out box. They may also be the focus of the solution.
    </p>
    <h2 class="py-2 font-light text-drawdown text-smd tracking-wide">
      Drawdown Adapted: A Solutions-Oriented Climate Change Resource for Youth
    </h2>
    <p class="pb-2">
      This resource offers top climate solutions identified by leading climate experts in a lively format accessible to K-12 students and non-specialists. The top 10 solutions are also available in Spanish. Young people all over the world are ready to act to ensure a survivable future. This resource provides reliable and up-to-date information in one source to guide that action towards a bright future. This Open Educational Resource (OER) is an adaptation of top climate solutions from Project Drawdown that lists and describes the top 82 most impactful solutions for reversing global warming. This adaptation is adjusted to target a 5th grade reading level, but students younger and older will enjoy the engaging writing, descriptive illustrations, and vibrant photos. Solutions include a written description and story of the solution, illustrations to help students visualize this solution in context, and data relevant to each solution, along with further research sources.
    </p>
    <p class="pb-2">
      This resource is created by a team at the University of Colorado led by Patrick Chandler, an experienced environmental educator and PhD candidate in Environmental Studies, and Beth Osnes, an associate professor of Theatre and Environmental Studies. With three undergraduate interns and two experienced bilingual PhD graduate students in Education, the team has taken on the challenge of adapting each solution for youth (see bios below). During the process, we have had our adaptations peer reviewed by 5th grade students and teachers. These solutions are classroom-ready to support climate education and to inspire meaningful climate action.
    </p>
    <h2 class="py-2 font-light text-drawdown text-smd tracking-wide">
      Project Drawdown: The book versus the website - how to navigate Project Drawdown
    </h2>
    <p class="pb-2">
      Our world is quickly changing; so too, top climate solutions adjust over time with these changes. In 2017 the book Drawdown: The Most Comprehensive Plan Ever to Reverse Global Warming made a splash at the top of The New York Times best seller list. It was the first publication to take a wholistic approach to studying the projected impacts of various climate solutions to determine which ones either pulled down or sequestered the most greenhouse gas. The book may have made it into your school library by now and is a great resource, especially for some of the introductory essays. However, the most up to date rating of top climate solutions are published in the 2020 open source, free downloadable publication: The Drawdown Review, available from the Project Drawdown website. We have based our adaptation of these solutions on this 2020 publication.     
    </p>
    <h2 class="py-2 font-light text-drawdown text-smd tracking-wide">
      Ranking the Solutions
    </h2>
    <p class="pb-2">
      You can visit the <a href="https://www.drawdown.org/" class="font-bold text-drawdown hover:underline">Project Drawdown website</a> and download your own free copy of <span class="text-drawdown italic">The Drawdown Review</span>. The summary and ranking on all of the solutions are listed beginning on page 86. For our adaptation of these solutions, we used the list of solutions based on Scenario 1 which puts us on track to reach drawdown in the mid-2060s. Drawdown is the point in the future when levels of greenhouse gases in the atmosphere stop climbing and start to steadily decline, thereby stopping catastrophic climate change. Learning about climate is a necessary step in preserving life on this planet, but enacting climate is what allow us to arrive at that point. “Of course, scenarios are stories of what could be, not what will be. What will be? That will be decided by our collective ambition and determined action this decade and beyond (page 74 The Drawdown Review).” Important to remember is that all the solutions are necessary for getting to drawdown.
    </p>
    <h2 class="py-2 font-light text-drawdown text-smd tracking-wide">
      Why Students Need Access to Top Solutions
    </h2>
    <p class="pb-2">
      Youth voices and actions matter. They can be effective communicators of climate change and can act to reduce global warming in their communities, states, nations, and the world. Researchers have found youth to be among the top trusted messengers of groups capable of influencing those within their circles of community, in part because of their lack of political agenda, direct truthfulness, and investment in the outcome of stabilizing the climate (<a href="http://" target="_blank" class="italic hover:underline">BU Institute for Sustainable Energy</a>). To make a difference they need access to impactful solutions. 
    </p>
    <h2 class="py-2 font-light text-drawdown text-smd tracking-wide">
      The genesis for this project
    </h2>
    <p class="pb-2">
      In 2019 Patrick and Beth were collaborating with a team of 5th grade teachers to use arts-based methods to support students in authoring solutions to climate change within their local context. Every student group created ingenious skits about ways to pick up trash, which is actually an issue of aesthetics and does not impact climate change! After that experience we set out to make top climate solutions accessible to students to put their creative ingenuity in service of true impact.
    </p>
    <h2 class="py-2 font-light text-drawdown text-smd tracking-wide">
      Our Partnership with Project Drawdown
    </h2>
    <p class="pb-2">
      After the successful launching of Project Drawdown with the publication of Drawdown: The Most Comprehensive Plan Ever to Reverse Global Warming, in 2018 the Drawdown Learn Conference brought together climate educators from around the US. Beth was invited to present on performance-based methods for engaging students in authoring local climate solutions. This work was featured by Drawdown founder and noted environmentalist, Paul Hawken, in the opening plenary session at the conference (see photo). Patrick and Beth both presented at the 2019 Drawdown Learn conference. Their workshop facilitated youth participants in authoring a plenary performance for the final evening conference celebration. In 2020 Dr. Elizabeth Bagley became the first director of Drawdown Learn at Project Drawdown. Our team has been working with her on this adaptation of the Drawdown solutions. Both Beth and Patrick are a part of Inside the Greenhouse, a project at the University of Colorado that supports creative climate communication. It is through Inside the Greenhouse that we partner with Project Drawdown in making this open-source resource available free of charge for all. We thank the Office of Outreach and Engagement at the University of Colorado for support of this project. This work will be submitted to the Climate Literacy Energy Awareness Network (CLEAN) to be peer-reviewed and promoted to climate educators nationally. It is available on the Inside the Greenhouse website and on enactingclimate.org. 
    </p>
    <!-- <img src="" alt=""> -->
    <h2 class="py-2 font-light text-drawdown text-smd tracking-wide">
      Holding A Place for Student Emotions Around Climate Change
    </h2>
    <p class="pb-2">
      As student knowledge and awareness about the consequences of climate change increase, so too can anxiety and despair about environmental loss and fear for the future. It is important to acknowledge and guide students in managing these feelings and emotions. Likewise, as a teacher you don’t need to have the answers, but you can acknowledge and share their questions. The future is unknown and depends in large part upon what we do in the present. Actively involving youth and adolescents while they are still young is important regarding climate related issues because research reveals that pessimism about addressing climate change increases with age, particularly from early to late adolescence (Ojala, 2012). With increased knowledge of climate challenges and biodiversity loss, youth mental health and well-being can be impacted. Researchers, such as CU Professor Emerita of Environmental Design, Louise Chawla, offers some key ways adults can help youth work through these feelings and maintain hope to actively address environmental problems constructively, such as sharing and listening to each other, spending time outdoors connecting with nature, building a community of shared concerns, and valuing young people as partners in addressing environmental challenges and actively authoring and enacting solutions (Chawla 2020). 
    </p>
    <h2 class="py-2 font-light text-drawdown text-smd tracking-wide">
      Contact Us
    </h2>
    <p class="pb-2">
      Please feel free to reach out to Beth Osnes (beth.osnes@colorado.edu) or Patrick Chandler (patrick.chandler@colorado.edu) with any questions, requests for supporting professional development, or other ideas.
    </p>
    <h2 class="py-2 font-light text-drawdown text-smd tracking-wide">
      Works Cited
    </h2>
    <p class="pb-2">
      Chawla, Louise. (2020). Connecting to nature is good for kids—but they may need help in coping with a planet in peril. The Conversation. November 16.
    </p>
    <p class="pb-2">
      Ojala, M. (2012). “Regulating worry, promoting hope: How do children, adolescents, and young adults cope with climate change?” Int. J. Environ. Sci. Educ. 7, 537–561.
    </p>
    <p class="pb-2">
      Stevenson, K., Peterson, N. (2016). “Motivating climate action through fostering climate change hope and concern and avoiding despair among adolescents.” Sustainability 8, 1–10.
    </p>
  
  </div>
</template>

<script>
import { mapState, mapMutations} from "vuex";

export default {
  name: 'TeacherGuide',
  components: {
    
  },
  computed: {
        ...mapState(["solutionIndex"])
  },
  methods: {
        ...mapMutations(["setSolutionIndex"])
  },
}

</script>